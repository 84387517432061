<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>
            <div class="th-content">
              Nom complet
            </div>
          </th>
          <th>
            <div class="th-content">
              Type
            </div>
          </th>
          <th>
            <div class="th-content">
              Action
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr-switch
          v-for="(u, index) in users"
          :key="index"
          :user="u"
        />
      </tbody>
    </table>
    <!-- <p class="font-13 text-center mt-4 mb-1 text-muted">
                        <a
                          class="text-primary"
                          href="javascript:void(0);"
                        >Click here</a> to see the full product list
                      </p> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trSwitch from './trSwitch.vue';
export default {
    components: {trSwitch},
    data(){
        return  {

        }
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        })
    }
}
</script>

<style>

</style>