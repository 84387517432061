<template>
  <div>
    <!-- <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    /> -->
    <div class="row layout-spacing pt-2 mt-4">
      <div class="col-12">
        <div class="cover-image-area">
          <img
            :src="cover"
            class="cover-img"
          >
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-4 mb-4">
        <div class="profile-left">
          <div class="image-area">
            <img
              class="user-image"
              src="assets/img/profile-16.jpg"
              v-if="hasPhoto"
            >
            <i
              v-else
              class="las la-user la-5x"
            />
            <a
              href="javascript:void(0)"
              class="follow-area"
            >
              <i class="las la-pen" />
            </a>
          </div>
          <div class="info-area">
            <h6>{{ fullname }}</h6>
            <p>{{ typeCompte }}</p>
            <button>Follow</button>
          </div>
          <div class="profile-numbers">
            <div class="single-number">
              <a>
                <span class="number">42</span>
                <span class="number-detail">Projects</span>
              </a>
            </div>
            <div class="single-number">
              <a>
                <span class="number">9.5K</span>
                <span class="number-detail">Products</span>
              </a>
            </div>
            <div class="single-number">
              <a>
                <span class="number">04</span>
                <span class="number-detail">Tasks</span>
              </a>
            </div>
          </div>
          <div class="profile-tabs">
            <div
              class="nav flex-column nav-pills mb-sm-0 mb-3 mx-auto"
              id="v-border-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                class="nav-link active"
                id="v-border-pills-home-tab"
                data-toggle="pill"
                href="#v-border-pills-home"
                role="tab"
                aria-controls="v-border-pills-home"
                aria-selected="true"
              >Home</a>
              <a
                class="nav-link"
                id="v-border-pills-team-tab"
                data-toggle="pill"
                href="#v-border-pills-team"
                role="tab"
                aria-controls="v-border-pills-team"
                aria-selected="false"
              >Team</a>
              <a
                class="nav-link"
                id="v-border-pills-work-tab"
                data-toggle="pill"
                href="#v-border-pills-work"
                role="tab"
                aria-controls="v-border-pills-work"
                aria-selected="false"
              >Work in progress</a>
              <a
                class="nav-link"
                id="v-border-pills-products-tab"
                data-toggle="pill"
                href="#v-border-pills-products"
                role="tab"
                aria-controls="v-border-pills-products"
                aria-selected="false"
              >Products</a>
              <a
                class="nav-link"
                id="v-border-pills-orders-tab"
                data-toggle="pill"
                href="#v-border-pills-orders"
                role="tab"
                aria-controls="v-border-pills-orders"
                aria-selected="false"
              >Switch Account</a>
              <a
                class="nav-link"
                id="v-border-pills-settings-tab"
                data-toggle="pill"
                href="#v-border-pills-settings"
                role="tab"
                aria-controls="v-border-pills-settings"
                aria-selected="false"
              >Settings</a>
            </div>
          </div>
          <!-- <div class="highlight-info">
                  <img src="assets/img/company-1.jpg" />
                  <div class="highlight-desc">
                      <p>WS Retailer</p>
                      <p>Top seller of the month</p>
                  </div>
              </div> -->
        </div>
      </div>
      <div class="col-xl-9 col-lg-8 col-md-8">
        <div class="row tab-area-content">
          <div class="col-xl-7 col-lg-12 col-md-12 mb-4">
            <div
              class="tab-content"
              id="v-border-pills-tabContent"
            >
              <div
                class="tab-pane fade show active"
                id="v-border-pills-home"
                role="tabpanel"
                aria-labelledby="v-border-pills-home-tab"
              >
                <div class="profile-shadow">
                  <h6 class="font-15 mb-3">
                    Collections
                  </h6>
                  <div class="d-flex align-items-center overflow-auto">
                    <div class="mr-3">
                      <img
                        src="assets/img/product-1.jpg"
                        class="avatar-md img-thumbnail object-cover rounded-circle"
                      >
                      <p class="font-10 strong text-center mb-0">
                        Product
                      </p>
                    </div>
                    <div class="mr-3">
                      <img
                        src="assets/img/product-2.jpg"
                        class="avatar-md img-thumbnail object-cover rounded-circle"
                      >
                      <p class="font-10 strong text-center mb-0">
                        Product
                      </p>
                    </div>
                    <div class="mr-3">
                      <img
                        src="assets/img/product-3.jpg"
                        class="avatar-md img-thumbnail object-cover rounded-circle"
                      >
                      <p class="font-10 strong text-center mb-0">
                        Product
                      </p>
                    </div>
                    <div class="mr-3">
                      <img
                        src="assets/img/product-4.jpg"
                        class="avatar-md img-thumbnail object-cover rounded-circle"
                      >
                      <p class="font-10 strong text-center mb-0">
                        Product
                      </p>
                    </div>
                    <div class="mr-3">
                      <img
                        src="assets/img/product-5.jpg"
                        class="avatar-md img-thumbnail object-cover rounded-circle"
                      >
                      <p class="font-10 strong text-center mb-0">
                        Product
                      </p>
                    </div>
                    <div class="ml-3 d-none d-xl-block">
                      <p class="font-30 strong text-center text-primary mb-0">
                        <a class="pointer"><i class="las la-arrow-right" /></a>
                      </p>
                    </div>
                  </div>
                </div>
                <blockquote class="blockquote mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                </blockquote>
                <div class="card component-card_5">
                  <div class="type1">
                    <i class="lar la-envelope" />
                    <span>Mail</span>
                  </div>
                  <div class="card-body">
                    <div class="user-info">
                      <img
                        src="assets/img/profile-7.jpg"
                        class="card-img-top avatar-md"
                        alt="..."
                      >
                      <div class="media-body">
                        <h5 class="card-user_name">
                          Luke Ivory
                        </h5>
                        <p class="card-user_occupation">
                          Manager
                        </p>
                      </div>
                    </div>
                    <p class="card-text">
                      Maecenas nec mi vel lacus condimentum rhoncus dignissim egestas orci. Integer blandit porta placerat. Vestibulum in ultricies.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-team"
                role="tabpanel"
                aria-labelledby="v-border-pills-team-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Team
                    </h6>
                    <div class="single-team">
                      <div class="d-flex">
                        <div class="team-left">
                          <img src="assets/img/lightbox-9.jpg">
                          <p>23 Members</p>
                        </div>
                        <div class="team-right">
                          <h6>Team UI Alpha</h6>
                          <p>FORMED IN 20th Apr'20</p>
                          <button class="primary">
                            Connect
                          </button>
                          <ul>
                            <li class="text-success-teal">
                              <strong>Completed</strong> : 9 Projects
                            </li>
                            <li class="text-info">
                              <strong>Working On</strong> : 2 Projects
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="single-team">
                      <div class="d-flex">
                        <div class="team-left">
                          <img src="assets/img/lightbox-3.jpg">
                          <p>25 Members</p>
                        </div>
                        <div class="team-right">
                          <h6>Development Team (America)</h6>
                          <p>FORMED IN 20th Apr'20</p>
                          <button class="primary">
                            Connect
                          </button>
                          <ul>
                            <li class="text-success-teal">
                              <strong>Completed</strong> : 4 Projects
                            </li>
                            <li class="text-warning">
                              <strong>Pipeline</strong> : 3 Projects
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="single-team">
                      <div class="d-flex">
                        <div class="team-left">
                          <img src="assets/img/lightbox-1.jpg">
                          <p>7 Members</p>
                        </div>
                        <div class="team-right">
                          <h6>Sales Team</h6>
                          <p>FORMED IN 18th Jun'20</p>
                          <button class="primary">
                            Connect
                          </button>
                          <ul>
                            <li class="text-success-teal">
                              <strong>Completed</strong> : 4 Projects
                            </li>
                            <li class="text-warning">
                              <strong>Pipeline</strong> : 3 Projects
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-work"
                role="tabpanel"
                aria-labelledby="v-border-pills-work-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Work in progress
                    </h6>
                    <div class="progress-list">
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-success-teal">
                              Resolved
                            </h6>
                            <p class="mb-2 font-12 text-success-teal strong">
                              69000
                            </p>
                          </div>
                          <div class="progress-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-success position-relative"
                                role="progressbar"
                                style="width: 69%"
                                aria-valuenow="69"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="success-teal" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-secondary">
                              In Progress
                            </h6>
                            <p class="mb-2 font-12 text-secondary strong">
                              2500
                            </p>
                          </div>
                          <div class="progress-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-secondary  position-relative"
                                role="progressbar"
                                style="width: 20%"
                                aria-valuenow="20"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="secondary" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-warning">
                              Pending
                            </h6>
                            <p class="mb-2 font-12 text-warning strong">
                              8500
                            </p>
                          </div>
                          <div class="progress-issues-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-warning position-relative"
                                role="progressbar"
                                style="width: 11%"
                                aria-valuenow="11"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="warning" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-progress">
                        <div class="progress-details">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-2 font-12 text-danger">
                              Failed
                            </h6>
                            <p class="mb-2 font-12 text-danger strong">
                              8500
                            </p>
                          </div>
                          <div class="progress-issues-stats">
                            <div class="progress">
                              <div
                                class="progress-bar bg-gradient-danger position-relative"
                                role="progressbar"
                                style="width: 11%"
                                aria-valuenow="11"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="danger" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-products"
                role="tabpanel"
                aria-labelledby="v-border-pills-products-tab"
              >
                <div class="searchable-items card-box grid">
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-5.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 1
                          </p>
                          <p class="product-category">
                            Category One
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1001
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            5  <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-success">In Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-1.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 2
                          </p>
                          <p class="product-category">
                            Category two
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1975
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            3  <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-danger">No Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-2.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 3
                          </p>
                          <p class="product-category">
                            Category Three
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$2455
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            4 <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-danger">No Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <div class="item-content">
                      <div class="product-info">
                        <img
                          src="assets/img/product-3.jpg"
                          alt="avatar"
                        >
                        <div class="user-meta-info">
                          <p class="product-name">
                            Product 4
                          </p>
                          <p class="product-category">
                            Category Four
                          </p>
                        </div>
                      </div>
                      <div class="product-price">
                        <p class="product-category-addr">
                          <span>Price: </span>$1655
                        </p>
                      </div>
                      <div class="product-rating">
                        <p class="product-rating-inner">
                          <span>Rating: </span>
                          <a class="d-flex align-center">
                            2 <img
                              src="assets/img/star.png"
                              class="avatar-xxs ml-2"
                              alt="star"
                            >
                          </a>
                        </p>
                      </div>
                      <div class="product-stock-status">
                        <p class="product-stock-status-inner">
                          <small class="badge badge-success">In Stock</small>
                        </p>
                      </div>
                      <div class="action-btn">
                        <i class="lar la-edit text-primary font-20 mr-2 btn-edit-contact" />
                        <i class="lar la-trash-alt text-danger font-20 mr-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-orders"
                role="tabpanel"
                aria-labelledby="v-border-pills-orders-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <switch-account />                    
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-border-pills-settings"
                role="tabpanel"
                aria-labelledby="v-border-pills-settings-tab"
              >
                <div class="media">
                  <div class="profile-shadow w-100">
                    <h6 class="font-15 mb-3">
                      Settings
                    </h6>
                    <div
                      class="tab-pane rightbar-tab active"
                      id="settings-tab"
                      role="tabpanel"
                    >
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Account Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Sync Contacts
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Auto Update
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Recieve Notifications
                          </p>
                        </div>
                      </div>
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Mail Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Mail Auto Responder
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Auto Trash Delete
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input type="checkbox">
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Custom Signature
                          </p>
                        </div>
                      </div>
                      <h6 class="right-bar-heading p-2 px-3 mt-2 text-uppercase">
                        Chat Setting
                      </h6>
                      <div class="px-2">
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Show Online
                          </p>
                        </div>
                        <div class="switch-container mb-3 pl-2">
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked=""
                            >
                            <span class="slider round primary-switch" />
                          </label>
                          <p class="ml-3 text-dark">
                            Chat Notifications
                          </p>
                        </div>
                      </div>
                      <div class="px-2 text-center pt-4">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                        >
                          Set Default
                        </a>
                        <button
                          class="ripple-button ripple-button-primary btn-sm"
                          type="button"
                        >
                          <div class="ripple-ripple js-ripple">
                            <span class="ripple-ripple__circle" />
                          </div>
                          Ripple Effect
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-12 col-md-12">
            <div class="profile-info">
              <h5>Information géneral</h5>
              <div class="single-profile-info">
                <h6>Adresse</h6>
                <p>{{ address }}.</p>
              </div>
              <div class="single-profile-info">
                <h6>Email</h6>
                <p><a :href="'mailto:'+email">{{ email }}</a></p>
              </div>
              <div class="single-profile-info">
                <h6>Téléphone</h6>
                <p>{{ telephone }}</p>
              </div>
              <div class="single-profile-info">
                <h6>Dernière connexion</h6>
                <p>{{ lastConnexion|systemDateTime }}</p>
              </div>
                      
              <!-- <div class="single-profile-info">
                          <h6>Skills</h6>
                          <span class="badge badge-primary"> UI/UX </span>
                          <span class="badge badge-info"> Photoshop </span>
                          <span class="badge badge-secondary"> Angular </span>
                          <span class="badge outline-badge-danger"> Java </span>
                      </div> -->
              <div class="single-profile-info social">
                <h6>Social</h6>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                ><i class="lab la-facebook text-primary" /></a>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                ><i class="lab la-twitter text-info" /></a>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                ><i class="lab la-linkedin text-primary" /></a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                ><i class="lab la-instagram text-danger" /></a>
                <a
                  href="https://www.github.com/"
                  target="_blank"
                ><i class="lab la-github text-black" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import navbar from '../../components/navbar.vue';
const cover = require('../../assets/img/profileCover.png')
import SwitchAccount from '../../components/user/profile/switch.vue'
import { mapGetters } from 'vuex';
export default {
    components: { SwitchAccount },
    data(){
        return {
          cover,
          navbarItems: [
            {
                libelle: 'Utilisateurs',
                route: 'users'
            },
            {
              libelle: 'Profile'
            }
          ],
          pageIcon: 'la-user',
          pageTitle: 'Profile',
          pageDescription: 'Information sur le compte de l\'utilisateur',
        }
    },
    computed: {
        ...mapGetters({
            me: 'auth/me',
            typeComptes: 'auth/typeComptes'
        }),
        hasUser(){
          return this.me !== null
        },
        fullname(){
          if(this.hasUser) return this.me.fullname
          return '-'
        },
        hasPhoto(){
          if(this.hasUser) return this.me.photo !== null
          return false
        },
        typeCompte(){
          if(this.hasUser) return this.typeComptes.find(item => item.uid === this.me.typeCompte).libelle
          return '-'
        },
        email(){
          if(this.hasUser) return this.me.email
          return '-'
        },
        telephone(){
          if(this.hasUser) return this.me.telephone
          return '-'
        },
        lastConnexion(){
          if(this.hasUser) return this.me.lastConnexion
          return null
        },
        address(){
          if(this.hasUser) return this.me.address
          return '-'
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-left{
  position: relative;
  top: -200px;
  left: 10px;
  background: white;
  box-shadow: none;
}
</style>